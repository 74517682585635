import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import css from "./style.module.css";

const Linkifier = props => {
  const classNames = classnames({
    [css.linkifier]: true,
    [css.sm]: props.size === "sm",
    [css.md]: props.size === "md",
    [css.lg]: props.size === "lg",
    [css.active]: props.active
  });
  return (
    <div className={classNames} {...props}>
      {props.children}
    </div>
  );
};

export default Linkifier;

Linkifier.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  active: PropTypes.oneOf([0, 1])
};
