import React from 'react';
import css from './style.module.css';

const JumboInput = ({ field, form, ...props }) => {
  return (
    <div className={css.input}>
      <input {...field} {...props} formNoValidate />
    </div>
  );
};

export default JumboInput;
