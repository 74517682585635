import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Linkifier from "../../components/Linkifier";
import css from "./style.module.css";
import config from "../../config";

const LoggedOutHeader = () => (
  <header className={css.header}>
    <div>
      <Linkifier>
        <a href={config.URLS.BOXOFFICE_PRO}>
          <FaArrowLeft size={20} />
        </a>
      </Linkifier>
      <Separator />
      <Linkifier>
        <Link to="/login">S'identifier</Link>
      </Linkifier>
    </div>
    <Linkifier>
      <a href={config.URLS.SUBSCRIBE}>Créer un compte</a>
    </Linkifier>
  </header>
);

export default LoggedOutHeader;

const Separator = () => <span className={css.separator}>&nbsp;</span>;
