import gql from "graphql-tag";

// Login
export const loginMutation = gql`
  mutation loginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

// Update user
export const accountMutation = gql`
  mutation accountMutation($input: updateUserProfileInput!) {
    user {
      updateMyProfile(input: $input) {
        user {
          firstName
          lastName
          email
          mobilePhone
          officePhone
        }
      }
    }
  }
`;

// Request password reset
export const sendResetPasswordMutation = gql`
  mutation sendResetPasswordMutation($email: String!) {
    sendResetPasswordRequest(email: $email) {
      result {
        success
        message
      }
      email
    }
  }
`;

// Reset password
export const resetPasswordMutation = gql`
  mutation resetPasswordMutation($resetToken: String!, $password: String!) {
    resetUserPassword(resetToken: $resetToken, password: $password) {
      result {
        success
        message
      }
      email
    }
  }
`;
