import React from 'react';
import css from './style.module.css';

const A = props => (
  <a className={css.a} {...props}>
    {props.children}
  </a>
);

export default A;
