import { ApolloClient, InMemoryCache } from "apollo-boost";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import Cookies from "js-cookie";
import config from "../config";

export const createApolloClient = (apiHost, token) => {
  const httpLink = createHttpLink({
    uri: apiHost
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  });
  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
  return apolloClient;
};

// Return user products that grant him access.
export const getAcceptedProducts = account => {
  const okProducts = Object.values(config.PRODUCTS);
  let products = [];
  for (let product of account.products) {
    if (okProducts.indexOf(product.codename) > -1 && product.enabled)
      products.push(product.codename);
  }
  return products;
};

export const saveToken = token =>
  Cookies.set("token", token, {
    domain: getCookieDomain()
  });

export const removeToken = () =>
  Cookies.remove("token", {
    domain: getCookieDomain()
  });

const getCookieDomain = () => {
  const hostname = window.location.hostname;
  if (hostname.indexOf(".") > -1) {
    const segments = hostname.split(".");
    return `.${segments.slice(-2).join(".")}`;
  }
  return hostname;
};
