import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import ReactLoading from "react-loading";

import config from "../../config";
import LoggedOutLayout from "../../components/LoggedOutLayout";
import JumboInputGroup from "../../components/JumboInputGroup";
import JumboInput from "../../components/JumboInput";
import { AuthConsumer } from "../../lib/authContext";
import LoggedOutActionsContainer from "../../components/LoggedOutActionsContainer";
import Linkifier from "../../components/Linkifier";
import Message from "../../components/Message";
import { createApolloClient } from "../../lib/helpers";
import { sendResetPasswordMutation } from "../../gql/mutations";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email invalide")
    .required("Veuillez entrer votre adresse email")
});

const handleSubmit = async (
  values,
  { setStatus, setSubmitting, resetForm }
) => {
  try {
    // Validate login form.
    const variables = await validationSchema.validate(values);
    try {
      const boClient = createApolloClient(config.API_HOST);
      // Send password reset email.
      const response = await boClient.mutate({
        mutation: sendResetPasswordMutation,
        variables
      });
      const { success } = response.data.sendResetPasswordRequest.result;
      const statusMessage = success
        ? `Un email de réinitialisation de mot de passe a été envoyé à ${
            variables.email
          }`
        : "Veuillez vérifier votre adresse email et réessayer";
      if (success) resetForm();
      // Show response message.
      setStatus(statusMessage);
    } catch (apiError) {
      setStatus("Échec de l'opération");
    }
  } catch (validationError) {
    // Validation failed.
    setStatus(validationError.message);
  }
  setSubmitting(false);
};

const ForgotPassword = () => {
  useEffect(() => {
    document.title = `Mot de passe oublié - ${config.WEBSITE_NAME}`;
  }, []);

  return (
    <AuthConsumer>
      {({ loggedIn }) => {
        return loggedIn ? (
          <Redirect to="/" />
        ) : (
          <LoggedOutLayout>
            <Formik
              initialValues={{ email: "" }}
              onSubmit={handleSubmit}
              render={({ status, isSubmitting }) => (
                <Form noValidate>
                  <JumboInputGroup>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      component={JumboInput}
                    />
                  </JumboInputGroup>
                  {status && (
                    <Message style={{ marginTop: "10px" }}>{status}</Message>
                  )}
                  <LoggedOutActionsContainer>
                    <div />
                    <Linkifier size="lg">
                      <button type="submit" disabled={isSubmitting}>
                        Soumettre&nbsp;
                        {isSubmitting ? (
                          <ReactLoading type="spin" width={18} height={18} />
                        ) : (
                          <span>&rarr;</span>
                        )}
                      </button>
                    </Linkifier>
                  </LoggedOutActionsContainer>
                </Form>
              )}
            />
          </LoggedOutLayout>
        );
      }}
    </AuthConsumer>
  );
};

export default ForgotPassword;
