import React from 'react';
import PropTypes from 'prop-types';
import css from './style.module.css';

const NavMenu = ({ items }) => (
  <ul className={css.navMenu}>
    {items.map((item, i) => (
      <NavItem key={i} {...item} />
    ))}
  </ul>
);

export default NavMenu;

const NavItem = ({ label, href, active }) => (
  <li className={css.navItem}>
    <a href={href} className={active && css.active}>
      {label}
    </a>
  </li>
);

const navMenuItemShape = PropTypes.shape({
  label: PropTypes.string,
  href: PropTypes.string,
});

NavMenu.propTypes = {
  items: PropTypes.arrayOf(navMenuItemShape),
};
