const NODE_ENV = process.env.NODE_ENV || "development";
const APP_ENV = process.env.REACT_APP_ENV || NODE_ENV;

const config = {
  development: {
    API_HOST: "http://localhost:4000",
    MP_API_HOST: "http://localhost:7000/graphql"
  },
  staging: {
    API_HOST: "https://staging-api.boxofficepro.com",
    MP_API_HOST: "https://staging-api.webediamovies.pro:7000/graphql"
  },
  production: {
    API_HOST: "https://api.boxofficepro.com",
    MP_API_HOST: "https://api.webediamovies.pro/graphql"
  }
};

const conf = {
  ...config[APP_ENV],
  WEBSITE_NAME: "Boxoffice",
  PRODUCTS: {
    BOXOFFICE_PRO: "boxofficepro",
    BOXOFFICE_PULSE: "boxofficepulse"
  },
  URLS: {
    BOXOFFICE_PRO: "https://www.boxofficepro.fr",
    MAGAZINE: "https://www.boxofficefrance.fr/mags",
    PULSE: "https://pulse.boxofficepro.fr",
    FILMS: "https://www.boxofficefrance.fr/films.php",
    GUIDE: "https://www.boxofficefrance.fr/guide",
    SUBSCRIBE: "https://www.boxofficefrance.fr/inscription.php",
    WMP_LOGIN: "https://www.webediamovies.pro/login"
  },
  PASSWORD_MIN_LENGTH: 8
};

export default conf;
