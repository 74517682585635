import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Redirect } from "react-router-dom";
import Header from "../../components/Header";
import css from "./style.module.css";
import Input from "../../components/Input";
import { Container, Row, Col } from "../../components/layout";
import InputGroup from "../../components/InputGroup";
import Button from "../../components/Button";
import A from "../../components/A";
import Title from "../../components/Title";
import config from "../../config";
import { Mutation } from "react-apollo";
import { accountMutation } from "../../gql/mutations";
import Message from "../../components/Message";
import Checkbox from "../../components/Checkbox";
import { AuthConsumer } from "../../lib/authContext";

const Account = () => {
  const [changePassword, setChangePassword] = useState(false);
  useEffect(() => {
    document.title = `Mon compte - ${config.WEBSITE_NAME}`;
  }, []);

  let messageTimeout = null;

  const getAccountSchema = () => {
    const phoneSchema = Yup.string().matches(
      /^\d{10}$/,
      "Le numéro de téléphone doit comporter 10 chiffres"
    );
    const schemaShape = {
      firstName: Yup.string().required("Ce champ est requis"),
      lastName: Yup.string().required("Ce champ est requis"),
      email: Yup.string()
        .email("Email invalide")
        .required("Ce champ est requis"),
      mobilePhone: phoneSchema,
      officePhone: phoneSchema
    };

    if (changePassword) {
      schemaShape.password = Yup.string()
        .required("Ce champ est requis")
        .min(
          config.PASSWORD_MIN_LENGTH,
          `Le mot de passe doit comporter au moins ${
            config.PASSWORD_MIN_LENGTH
          } caractères.`
        );
    }

    return Yup.object().shape(schemaShape);
  };

  const handleSubmit = (values, { setSubmitting, setStatus }, mutate) => {
    const input = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobilePhone: values.mobilePhone,
      officePhone: values.officePhone
    };
    if (changePassword) {
      input.password = values.password;
    }
    if (messageTimeout) {
      clearTimeout(messageTimeout);
    }
    mutate({ variables: { input } })
      .then(() => {
        setStatus({
          type: "success",
          message: "Sauvegarde réussie"
        });
        messageTimeout = setTimeout(() => setStatus(null), 3000);
      })
      .catch(() =>
        setStatus({
          type: "danger",
          message: "Échec de l'opération"
        })
      );
    setSubmitting(false);
  };

  return (
    <AuthConsumer>
      {({ loggedIn, account, logout }) => {
        if (loggedIn) {
          return account ? (
            <div>
              <Header />
              <Container>
                <Row>
                  <Col middle>
                    <Title>Mon compte</Title>
                    <Mutation mutation={accountMutation}>
                      {mutate => (
                        <Formik
                          initialValues={{ ...account, password: "" }}
                          validationSchema={getAccountSchema()}
                          onSubmit={(values, actions) =>
                            handleSubmit(values, actions, mutate)
                          }
                          render={({ isSubmitting, status }) => (
                            <Form noValidate>
                              <InputGroup>
                                <Field
                                  name="firstName"
                                  label="Prénom"
                                  component={Input}
                                />
                                <Field
                                  name="lastName"
                                  label="Nom"
                                  component={Input}
                                />
                              </InputGroup>
                              <Field
                                type="email"
                                name="email"
                                label="Email"
                                component={Input}
                              />
                              <Field
                                name="mobilePhone"
                                label="Téléphone portable"
                                component={Input}
                              />
                              <Field
                                name="officePhone"
                                label="Téléphone professionnel"
                                component={Input}
                              />
                              <Checkbox
                                label="Changer le mot de passe"
                                onChange={checked => setChangePassword(checked)}
                              />
                              {changePassword && (
                                <Field
                                  type="password"
                                  name="password"
                                  label="Mot de passe"
                                  component={Input}
                                />
                              )}
                              {status && (
                                <Message
                                  color={status.type}
                                  style={{ marginBottom: "1rem" }}
                                >
                                  {status.message}
                                </Message>
                              )}
                              <div className={css.actionsContainer}>
                                <A onClick={logout}>Se déconnecter</A>
                                <Button type="submit" disabled={isSubmitting}>
                                  Sauvegarder
                                </Button>
                              </div>
                            </Form>
                          )}
                        />
                      )}
                    </Mutation>
                  </Col>
                </Row>
              </Container>
            </div>
          ) : null;
        } else return <Redirect to="/login" />;
      }}
    </AuthConsumer>
  );
};

export default Account;
