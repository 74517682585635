import React, { createRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import css from "./style.module.css";

const Input = ({ label, field, form: { errors }, ...props }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const inputRef = createRef();
  const input = <input {...field} {...props} ref={inputRef} formNoValidate />;

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    inputRef.current.type =
      inputRef.current.type === "password" ? "text" : "password";
  };

  return (
    <div className={css.input}>
      <label>{label}</label>
      {props.type === "password" ? (
        <div className={css.inputWrapper}>
          {input}
          <span className={css.eye} onClick={togglePasswordVisibility}>
            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
          </span>
        </div>
      ) : (
        input
      )}
      {errors[field.name] && (
        <div className={css.error}>{errors[field.name]}</div>
      )}
    </div>
  );
};

export default Input;
