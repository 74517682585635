import React from "react";
import LoggedOutHeader from "../LoggedOutHeader";
import logo from "../../assets/img/boxoffice-logo.png";
import logoSmall from "../../assets/img/boxoffice-logo-small.png";
import css from "./style.module.css";

const LoggedOutLayout = ({ children }) => (
  <div className={css.loggedOutLayout}>
    <LoggedOutHeader />
    <div className={css.loggedOutContent}>
      <div className={css.logoContainer}>
        <img
          src={logo}
          srcSet={`${logoSmall} 500w, ${logo} 645w`}
          sizes="(max-width: 479px) 66vw, 210px"
          alt="Boxoffice logo"
        />
      </div>
      {children}
    </div>
  </div>
);

export default LoggedOutLayout;
