import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ReactLoading from "react-loading";
import { Redirect } from "react-router-dom";

import JumboInput from "../../components/JumboInput";
import JumboInputGroup from "../../components/JumboInputGroup";
import Linkifier from "../../components/Linkifier";
import Message from "../../components/Message";
import A from "../../components/A";
import config from "../../config";
import {
  createApolloClient,
  getAcceptedProducts,
  saveToken
} from "../../lib/helpers";
import { loginMutation } from "../../gql/mutations";
import { accountQuery } from "../../gql/queries";
import { AuthConsumer } from "../../lib/authContext";
import LoggedOutLayout from "../../components/LoggedOutLayout";
import LoggedOutActionsContainer from "../../components/LoggedOutActionsContainer";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email invalide")
    .required("Champ obligatoire est vide"),
  password: Yup.string().required("Champ obligatoire est vide")
});

const boClient = createApolloClient(config.API_HOST);

const handleSubmit = async (values, actions) => {
  try {
    // Validate login form.
    const variables = await validationSchema.validate(values);
    try {
      // Trigger the login mutation.
      const loginRes = await boClient.mutate({
        mutation: loginMutation,
        variables
      });
      const token = loginRes.data.login.token;
      const mpClient = createApolloClient(config.MP_API_HOST, token);
      const accountRes = await mpClient.query({ query: accountQuery });
      const account = accountRes.data.viewer;
      const products = getAcceptedProducts(account);
      if (products.length) {
        saveToken(token);
        redirect(products);
      } else {
        actions.setStatus(<NoPermission />);
      }
    } catch (apiError) {
      // Login failed.
      actions.setStatus(loginErrorToString(apiError));
    }
  } catch (validationError) {
    // Validation failed.
    actions.setStatus(validationError.message);
  }
  actions.setSubmitting(false);
};

// Redirect user to the proper website after successful login.
const redirect = products => {
  const { referrer } = document;
  const { URLS, PRODUCTS } = config;
  let destination = URLS.BOXOFFICE_PRO;
  if (
    products.indexOf(PRODUCTS.BOXOFFICE_PULSE) > -1 &&
    referrer.indexOf(URLS.PULSE) === 0
  ) {
    // User has come from Pulse and has access to it.
    destination = URLS.PULSE;
  } else if (products.indexOf(PRODUCTS.BOXOFFICE_PRO) > -1) {
    // User has access to Boxoffice Pro.
    if (referrer.indexOf(URLS.MAGAZINE) === 0) {
      // User has come from Magazine.
      destination = URLS.MAGAZINE;
    } else if (referrer.indexOf(URLS.FILMS) === 0) {
      // User has come from Films.
      destination = URLS.FILMS;
    }
  }
  window.location.href = destination;
};

const loginErrorToString = error => {
  if (error.graphQLErrors) {
    if (error.graphQLErrors[0].extensions.code === "UNAUTHENTICATED") {
      return "Votre identifiant ou mot de passe est incorrect";
    }
  }
  return "Échec de l'opération";
};

const Login = () => {
  useEffect(() => {
    document.title = `S'identifier - ${config.WEBSITE_NAME}`;
  }, []);
  return (
    <AuthConsumer>
      {({ loggedIn }) => {
        return loggedIn ? (
          <Redirect to="/" />
        ) : (
          <LoggedOutLayout>
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={handleSubmit}
              render={({ status, isSubmitting }) => (
                <Form noValidate>
                  <JumboInputGroup>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      component={JumboInput}
                    />
                    <Field
                      type="password"
                      name="password"
                      placeholder="Mot de passe"
                      component={JumboInput}
                    />
                  </JumboInputGroup>
                  {status && (
                    <Message style={{ marginTop: "10px" }}>{status}</Message>
                  )}
                  <LoggedOutActionsContainer>
                    <Linkifier size="sm">
                      <a href="/forgot-password">Mot de passe oublié</a>
                    </Linkifier>
                    <Linkifier size="lg">
                      <button type="submit" disabled={isSubmitting}>
                        S'identifier&nbsp;
                        {isSubmitting ? (
                          <ReactLoading type="spin" width={18} height={18} />
                        ) : (
                          <span>&rarr;</span>
                        )}
                      </button>
                    </Linkifier>
                  </LoggedOutActionsContainer>
                </Form>
              )}
            />
          </LoggedOutLayout>
        );
      }}
    </AuthConsumer>
  );
};

export default Login;

const NoPermission = () => (
  <span>
    Vous n’avez pas accès à Boxoffice. Cliquez{" "}
    <A href={config.URLS.SUBSCRIBE} style={{ color: "#fff" }}>
      ici
    </A>{" "}
    pour créer un compte ou connectez vous à{" "}
    <A href={config.URLS.WMP_LOGIN} style={{ color: "#fff" }}>
      Webedia Movies Pro
    </A>
  </span>
);
