import React, { useState } from "react";
import PropTypes from "prop-types";
import css from "./style.module.css";

const Checkbox = ({ label, onChange, style }) => {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    const isChecked = !checked;
    setChecked(isChecked);
    if (onChange) {
      onChange(isChecked);
    }
  };
  return (
    <label className={css.checkbox} style={style}>
      <span>{label}</span>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <span className={css.checkmark} />
    </label>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func
};
