import gql from 'graphql-tag';

export const accountQuery = gql`
  query accountQuery {
    viewer {
      id
      email
      firstName
      lastName
      mobilePhone
      officePhone
      company {
        id
      }
      products {
        id
        codename
        enabled
      }
    }
  }
`;
