import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import css from "./style.module.css";

const Message = ({ color, children, style }) => {
  const classNames = classnames({
    [css.message]: true,
    [css.danger]: color === "danger",
    [css.success]: color === "success"
  });
  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  );
};

export default Message;

Message.propTypes = {
  color: PropTypes.oneOf(["danger", "success"])
};
