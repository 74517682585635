import React from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";

const LoadingPlaceholder = ({ height }) => (
  <div
    style={{
      height,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    <ReactLoading type="spin" color="#bababa" width={32} height={32} />
  </div>
);

export default LoadingPlaceholder;

LoadingPlaceholder.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
