import React from 'react';
import classnames from 'classnames';
import css from './style.module.css';

export const Container = ({ children, style }) => (
  <div className={css.container} style={style}>
    {children}
  </div>
);

export const Row = ({ children, className, style }) => {
  const classNames = classnames({
    [css.row]: true,
    [className]: className,
  });
  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  );
};

export const Col = ({ children, middle, className, style }) => {
  const classNames = classnames({
    [css.col]: true,
    [css.middle]: middle,
    [className]: className,
  });
  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  );
};

export const Center = ({ children, style }) => (
  <div className={css.center} style={style}>
    {children}
  </div>
);
