import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Redirect, Link } from "react-router-dom";
import * as Yup from "yup";
import ReactLoading from "react-loading";
import qs from "qs";

import config from "../../config";
import LoggedOutLayout from "../../components/LoggedOutLayout";
import JumboInputGroup from "../../components/JumboInputGroup";
import JumboInput from "../../components/JumboInput";
import { AuthConsumer } from "../../lib/authContext";
import LoggedOutActionsContainer from "../../components/LoggedOutActionsContainer";
import Linkifier from "../../components/Linkifier";
import Message from "../../components/Message";
import { createApolloClient } from "../../lib/helpers";
import { resetPasswordMutation } from "../../gql/mutations";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Champ obligatoire est vide")
    .min(
      config.PASSWORD_MIN_LENGTH,
      `Le mot de passe doit comporter au moins ${
        config.PASSWORD_MIN_LENGTH
      } caractères.`
    ),
  confirmPassword: Yup.string().required("Champ obligatoire est vide")
});

const handleSubmit = async (
  values,
  { setStatus, setSubmitting, resetForm },
  resetToken
) => {
  try {
    // Validate login form.
    const variables = await validationSchema.validate(values);
    if (variables.password !== variables.confirmPassword) {
      setStatus({
        success: false,
        message: `Les mots de passe saisis sont différents`
      });
    } else {
      try {
        const boClient = createApolloClient(config.API_HOST);
        const response = await boClient.mutate({
          mutation: resetPasswordMutation,
          variables: { password: variables.password, resetToken }
        });
        const { success } = response.data.resetUserPassword.result;
        if (success) resetForm();
        setStatus({
          success,
          message: success
            ? `Votre mot de passe a été changé avec succès.`
            : `Échec de l'opération`
        });
      } catch (apiError) {
        setStatus({ success: false, message: "Échec de l'opération" });
      }
    }
  } catch (validationError) {
    // Validation failed.
    setStatus({ success: false, message: validationError.message });
  }
  setSubmitting(false);
};

const ResetPassword = ({ location: { search } }) => {
  useEffect(() => {
    document.title = `Réinitializer mot de passe - ${config.WEBSITE_NAME}`;
  }, []);

  // Check if token exists in the URL
  const resetToken = qs.parse(search, { ignoreQueryPrefix: true }).token;
  if (!resetToken) return <Redirect to="/login" />;

  return (
    <AuthConsumer>
      {({ loggedIn }) => {
        return loggedIn ? (
          <Redirect to="/" />
        ) : (
          <LoggedOutLayout>
            <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              onSubmit={(values, actions) =>
                handleSubmit(values, actions, resetToken)
              }
              render={({ status, isSubmitting }) => {
                const success = status ? status.success : false;
                return (
                  <Form noValidate>
                    {!success && (
                      <JumboInputGroup>
                        <Field
                          type="password"
                          name="password"
                          placeholder="Nouveau mot de passe"
                          component={JumboInput}
                        />
                        <Field
                          type="password"
                          name="confirmPassword"
                          placeholder="Confirmez le mot de passe"
                          component={JumboInput}
                        />
                      </JumboInputGroup>
                    )}
                    {status && (
                      <Message style={{ marginTop: "10px" }}>
                        {status.message}
                      </Message>
                    )}
                    {!success ? (
                      <LoggedOutActionsContainer>
                        <Linkifier size="sm">
                          <Link to="/login">S'identifier</Link>
                        </Linkifier>
                        <Linkifier size="lg">
                          <button type="submit" disabled={isSubmitting}>
                            Soumettre&nbsp;
                            {isSubmitting ? (
                              <ReactLoading
                                type="spin"
                                width={18}
                                height={18}
                              />
                            ) : (
                              <span>&rarr;</span>
                            )}
                          </button>
                        </Linkifier>
                      </LoggedOutActionsContainer>
                    ) : (
                      <LoggedOutActionsContainer>
                        <span />
                        <Linkifier size="lg">
                          <Link to="/login">S'identifier</Link>
                        </Linkifier>
                      </LoggedOutActionsContainer>
                    )}
                  </Form>
                );
              }}
            />
          </LoggedOutLayout>
        );
      }}
    </AuthConsumer>
  );
};

export default ResetPassword;
