import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "../../pages/Login";
import Account from "../../pages/Account";
import ForgotPassword from "../../pages/ForgotPassword";
import ResetPassword from "../../pages/ResetPassword";
import Auth from "../Auth";

const App = () => {
  return (
    <Router>
      <Auth>
        <Switch>
          <Route exact path="/" component={Account} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
        </Switch>
      </Auth>
    </Router>
  );
};

export default App;
