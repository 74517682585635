import React from "react";
import css from "./style.module.css";
import logoSmall from "../../assets/img/boxoffice-logo-small.png";
import NavMenu from "../NavMenu";
import config from "../../config";

const Header = () => (
  <header className={css.header}>
    <div className={css.headerContainer}>
      <div className={css.colLeft}>
        <div className={css.mainMenu}>
          <a className={css.logo} href={config.URLS.BOXOFFICE_PRO}>
            <img src={logoSmall} alt="Boxoffice logo" />
          </a>
          <NavMenu items={menu1} />
        </div>
      </div>
      <div className={css.colRight} />
    </div>
  </header>
);

export default Header;

const menu1 = [
  {
    label: "Magazines",
    href: config.URLS.MAGAZINE
  },
  {
    label: "Pulse",
    href: config.URLS.PULSE
  },
  {
    label: "Films",
    href: config.URLS.FILMS
  },
  {
    label: "Le Guide",
    href: config.URLS.GUIDE
  }
];
