import React, { useEffect, useState, useMemo } from "react";
import Cookies from "js-cookie";
import decode from "jwt-decode";
import { AuthProvider } from "../lib/authContext";
import config from "../config";
import { accountQuery } from "../gql/queries";
import {
  createApolloClient,
  removeToken,
  getAcceptedProducts
} from "../lib/helpers";
import { ApolloProvider } from "react-apollo";
import LoadingPlaceholder from "./LoadingPlaceholder";

let tokenOk = false;

const fetchAccount = async (client, setLoggedIn, setAccount, setLoading) => {
  try {
    const result = await client.query({ query: accountQuery });
    const { viewer } = result.data;
    if (getAcceptedProducts(viewer).length) {
      setLoggedIn(true);
      setAccount(viewer);
    }
  } catch (error) {}
  setLoading(false);
};

const Auth = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(Cookies.get("token"));
  const [account, setAccount] = useState(null);

  const mpClient = useMemo(
    () => (token ? createApolloClient(config.MP_API_HOST, token) : null),
    [token]
  );

  useEffect(() => {
    if (token) {
      const tokenData = decode(token);
      // Check token expiration date.
      if (new Date(tokenData.exp * 1000) > new Date()) {
        tokenOk = true;
        fetchAccount(mpClient, setLoggedIn, setAccount, setLoading);
      }
    }
    if (!tokenOk) {
      setLoading(false);
    }
  }, [token, mpClient]);

  const logout = () => {
    removeToken();
    setToken(null);
    setAccount(null);
    setLoggedIn(false);
  };

  const authValue = {
    loggedIn,
    token,
    account,
    logout
  };

  return loading ? (
    <LoadingPlaceholder height="100vh" />
  ) : (
    <AuthProvider value={authValue}>
      {loggedIn ? (
        <ApolloProvider client={mpClient}>{children}</ApolloProvider>
      ) : (
        children
      )}
    </AuthProvider>
  );
};

export default Auth;
